export function getWorkosHandoffUrl(input: {
  domain: string;
  email?: string;
  provider?: 'GoogleOAuth' | 'SamlNonWorkOS';
  samlProviderId?: string;
  redirect?: string | null;
}): string {
  const { email, domain, provider, redirect, samlProviderId } = input;
  const params: { [v: string]: string } = { domain };
  if (email) {
    params.email = email;
  }
  if (provider) {
    params.provider = provider;
  }
  if (redirect) {
    params.redirect = redirect;
  }
  if (samlProviderId) {
    params.samlProviderId = samlProviderId;
  }
  const url = '/auth/sso/handoff?' + new URLSearchParams(params).toString();
  return url;
}
