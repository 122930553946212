import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { useCheckUserAuthenticationQuery } from '@watershed/shared-frontend/generated/urql';
import LoadingPage from '@watershed/shared-frontend/components/LoadingPage';
import getQueryStringRedirect from '@watershed/shared-frontend/utils/getQueryStringRedirect';
import Redirect from '@watershed/shared-frontend/components/Redirect';
import { useRouter } from 'next/router';

/**
 * If the user is authenticated, redirects to / (root). Otherwise, lets them
 * through to see the children.
 */
export default function RedirectAuthenticatedUsers({
  to = '/',
  children,
}: React.PropsWithChildren<{ to?: string }>) {
  const router = useRouter();
  const [result] = useCheckUserAuthenticationQuery();
  const { error } = result;

  if (isFetchingOrStale(result)) {
    return <LoadingPage />;
  }

  if (error?.response?.status === 401) {
    return <>{children}</>;
  }

  const queryStringRedirect = getQueryStringRedirect(router.query);
  if (queryStringRedirect) {
    return <Redirect to={queryStringRedirect} />;
  }
  return <Redirect to={to} />;
}
