import { Typography } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import * as Yup from 'yup';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import TextField from '@watershed/ui-core/components/Form/TextField';
import SendIcon from '@watershed/icons/components/Send';
import { useSendHelpRequest } from './surveys/useRequestHelp';
import { CompanySurveyHelpRequestContext } from '@watershed/shared-universal/companySurveys/logEvent';

const nameRegex =
  /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S+)?$/;

export default function AddDomainDialog({
  rootName,
  eventContext,
  setHasSubmittedState,
  setDialogOpen,
}: {
  rootName: string;
  eventContext: CompanySurveyHelpRequestContext | null;
  setHasSubmittedState: Dispatch<SetStateAction<boolean>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const requestHelp = useSendHelpRequest(eventContext);

  const handleSubmit = async (domain: string) => {
    Analytics.action('submitFeedback', {
      domain,
    });
    const message = `Supplier has requested to add a new domain ${domain}\n\nPage URL: ${window.document.location.href}`;
    if (eventContext) {
      // TODO: URGENT Please fix this by await-ing or void-ing this line.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      requestHelp('AddDomain', message);
    }

    setHasSubmittedState(true);
    setDialogOpen(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Formik
      initialValues={{ newDomain: '' }}
      onSubmit={async (values) => {
        await handleSubmit(values.newDomain);
      }}
      validationSchema={Yup.object().shape({
        newDomain: Yup.string()
          .required('Please enter a domain')
          .matches(nameRegex, 'Invalid domain'),
      })}
    >
      {(form) => (
        <DialogForm
          onClose={handleClose}
          maxWidth="sm"
          isSubmitting={form.isSubmitting}
          submitIcon={<SendIcon />}
          submit="Send message"
          header={{
            title: 'Missing domain',
          }}
        >
          <Typography variant="body2">
            <Trans>
              Add your correct domain below. Watershed will reach out with
              updated credentials mapped to your domain after confirming with{' '}
              {rootName}.
            </Trans>
          </Typography>
          <TextField
            id="newDomain"
            label={
              <Trans context="text field label for website domain">
                Your domain
              </Trans>
            }
          />
        </DialogForm>
      )}
    </Formik>
  );
}
