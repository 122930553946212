import { useCallback } from 'react';
import {
  CompanySurveyHelpRequestContext,
  CompanySurveyRequestHelpEventType,
} from '@watershed/shared-universal/companySurveys/logEvent';
import wsFetch from '@watershed/shared-frontend/utils/wsFetch';

/**
 * Sends a help request from the frontend. No response is expected. If no context
 * is provided, request is not sent.
 */
export function useSendHelpRequest(
  eventContext: CompanySurveyHelpRequestContext | null,
  { skip }: { skip?: boolean } = {}
): (
  eventType: CompanySurveyRequestHelpEventType,
  message: string
) => Promise<void> {
  return useCallback(
    async (eventType: CompanySurveyRequestHelpEventType, message: string) => {
      if (!eventContext || skip) {
        return;
      }
      await wsFetch(`/send_help_request/${encodeURIComponent(eventType)}`, {
        method: 'POST',
        body: JSON.stringify({
          eventContext,
          message,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    [eventContext, skip]
  );
}
