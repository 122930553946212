import React from 'react';
import { useRouter } from 'next/router';
import { parseQueryParam } from '@watershed/shared-universal/utils/queryParamUtils';
import must from '@watershed/shared-universal/utils/must';
import hoistNonReactStatics from 'hoist-non-react-statics';
import pickBy from 'lodash/pickBy';

/**
 * This is a utility for safely extracting "route params" from the router. By
 * "route params" we mean `router.query` values that come from the file path,
 * like `orgId` in `/orgs/[orgId]/foo.tsx` or `/orgs/[orgId].tsx`.
 *
 * NOTE: This is not meant to be used for normal query string parameters. Just
 * use `router.query` directly for that. As a general expectation, we can't
 * assume that query string parameters are always present, so components will
 * have to be able to handle the case where they don't exist.
 *
 * Why is this utility useful? They key is that it waits for `router.isReady` to
 * be true. A quirk of Next.js's "Automatic static optimization" feature is that
 * for the pages to be statically rendered, they can't be aware of any route
 * params values at build time. This means that `router.query` is empty on the
 * first render of any page that was pre-rendered via automatic static
 * optimization. The `router.isReady` value corresponds to when `router.query`
 * is populated, which is true on the first render immediately after the initial
 * render.
 *
 * See Next.js docs for more info:
 * https://nextjs.org/docs/advanced-features/automatic-static-optimization#how-it-works
 */
export default function withRouteParams<
  K extends keyof P & string,
  // This currently enforces that there are `paramKeys` values for each of the
  // component's props.
  // TODO: What it _doesn't_ do is error if there are extra `paramKeys` supplied
  // that don't correspond to any of the component's props.
  P extends { [key in K]: string },
>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>,
  // when paramKeys is an Object, it's a Record<K, boolean> where the boolean signifies whether to include it in the react key or not
  paramKeys: Array<K> | Record<K, boolean>,
  options: {
    /**
     * By default, do the useful thing of applying a unique React key based on
     * the applicable route params, so that the component will be unmounted and
     * remounted when the route params change. This is important when e.g.
     * navigating from `/thing/123` to `/thing/456`, because otherwise the page
     * component won't be remounted which can lead to stale component state
     * which causes bugs. However, in special cases we want to disable this
     * behavior.
     */
    applyReactKey?: boolean;
  } = {}
) {
  const { applyReactKey = true } = options;

  function WithRouteParamsWrapper() {
    const router = useRouter();
    /**
     * On first render, `router.isReady` is `false`, means that `router.query` is
     * empty. This is really funky if your page expects certain query parameters to
     * be present. This HOC will wait to render the component until the router is ready.
     */
    if (!router.isReady) {
      return null;
    }
    const paramKeysIsArray = Array.isArray(paramKeys);
    const params = (
      paramKeysIsArray ? paramKeys : (Object.keys(paramKeys) as Array<K>)
    ).reduce(
      (memo, key) => {
        memo[key] = must(
          parseQueryParam(router.query[key]),
          `Expected query param ${key}`
        );
        return memo;
      },
      {} as { [key in K]: string }
    );
    return (
      // @ts-expect-error I don't know how to fix this type error.
      <WrappedComponent
        key={
          applyReactKey
            ? JSON.stringify(
                pickBy(params, (param, key: K) =>
                  paramKeysIsArray ? true : paramKeys[key]
                )
              )
            : undefined
        }
        {...params}
      />
    );
  }

  WithRouteParamsWrapper.displayName = `WithRouteParams(${
    WrappedComponent.displayName ?? WrappedComponent.name
  })`;

  // Hoist any non-React statics from the wrapped component to the HoC, such as
  // `layout`.
  return hoistNonReactStatics(WithRouteParamsWrapper, WrappedComponent);
}

// A variant on withRouteParams where the route params are optional. Useful in
// cases where the params come from the URL query string, as opposed to the
// route.
export function withOptionalRouteParams<
  K extends keyof P & string,
  P extends { [key in K]?: string | null },
>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>,
  paramKeys: Array<K>
) {
  function WithOptionalRouteParamsWrapper() {
    const router = useRouter();
    if (!router.isReady) {
      return null;
    }
    const params = paramKeys.reduce(
      (memo, key) => {
        memo[key] = parseQueryParam(router.query[key]);
        return memo;
      },
      {} as { [key in K]: string | null }
    );
    // @ts-expect-error I also don't know how to fix this type error.
    return <WrappedComponent {...params} />;
  }

  WithOptionalRouteParamsWrapper.displayName = `WithOptionalRouteParams(${
    WrappedComponent.displayName ?? WrappedComponent.name
  })`;

  return WithOptionalRouteParamsWrapper;
}
