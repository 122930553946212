import { BadInputError } from '@watershed/errors/BadInputError';
import normalizeEmail from './normalizeEmail';

export default function parseEmail(
  email: string,
  { normalize = false }: { normalize?: boolean } = {}
): Array<string> {
  const emailToParse = normalize ? normalizeEmail(email) : email;
  const [handle, domain] = emailToParse.split('@');
  BadInputError.invariant(
    handle,
    `Cannot parse handle from email address: "${email}"`
  );
  BadInputError.invariant(
    domain,
    `Cannot parse domain from email address: "${email}"`
  );
  return [handle, domain];
}
