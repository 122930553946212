import invariant from 'invariant';
import { ValueOf } from './utilTypes';

export default function stringToEnum<T>(enumObj: T, value: string): ValueOf<T>;
export default function stringToEnum<T, D>(
  enumObj: T,
  value: string,
  defaultValue: D
): ValueOf<T> | D;

export default function stringToEnum<T extends {}, D>(
  enumObj: T,
  value: string,
  defaultValue?: D
): ValueOf<T> | D {
  const valueExists = Object.values(enumObj).some((v) => v === value);
  if (!valueExists && defaultValue !== undefined) {
    return defaultValue;
  }
  invariant(
    valueExists,
    `stringToEnum: Could not find literal enum value "${value}"`
  );
  return value as unknown as ValueOf<T>;
}
